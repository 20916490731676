import $ from 'jquery';

const Preloader = () => {
    const setPreloader = () => {
        $(window).on('load', function () {
            removePreloader();
        });
    };

    const removePreloader = () => {

        setTimeout(() => {
            $('body').removeClass('position-fixed');
            $(".k-preloader").fadeOut(400);
        }, 1000)
    };

    const showPreloader = () => {
        $('body').addClass('position-fixed');
        $(".k-preloader").fadeIn(400);
    };

    return {
        showPreloader,
        removePreloader,
        setPreloader
    }
};

export default Preloader();