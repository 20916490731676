<template>
	<div class="z-icon" v-html="iconSvg">
	</div>
</template>

<script>
	import icons from './icons'

	export default {
		name: "KIcon",
		props: {
			icon: {
				type: String,
				required: true,
				validator(value) {
					return icons.hasOwnProperty(value);
				}
			}
		},
		computed: {
			iconSvg() {
				return icons[this.icon];
			}
		}
	}
</script>

<style lang="scss">
	.z-icon {
		display: inline-flex;
		align-self: center;
		justify-content: center;

		svg, img {
			height: 1em;
			width: 1em;
			fill: currentColor;
			top: .25em;
			position: relative;
		}
	}
</style>
