document.addEventListener("DOMContentLoaded", () => {
    const hamburger = document.querySelector(".hamburger");
    const mobileMenu = document.querySelector(".header--mobile");
    const mobileHeader = document.querySelector(".header_mobile");
    const crossHeader = document.querySelector(".close-menu");

    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('is-active')
        
        mobileHeader.classList.toggle('header_mobile--open')
        mobileMenu.classList.toggle('header--mobile-on')
    });
    
    crossHeader.addEventListener('click', () => {
        hamburger.classList.remove('is-active')
        mobileMenu.classList.remove('header--mobile-on')
    });
})