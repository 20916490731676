import anime from 'animejs'
import { getCounters } from '../../api/bag'
window.anime = anime

export const countersAnim2 = async () => {
    const {counters} = (await getCounters()).data;
    const ids = ["first_counter1", "second_counter2", "third_counter3"]
    if (counters.length <= 0) return

    for(let i = 0; i < 3; i++) {
        console.log(ids[i])
        console.log(counters[i])
        anime({
            targets: `#${ids[i]} .title`,
            textContent: [0, counters[i].value],
            round: 1,
            duration: 5000,
            easing: 'easeInOutExpo'
        })
    }

}