import "@/app/styles/app.scss"
import "./plugins/vue";

import "./pages/home";

import "./events";

import Preloader from "./plugins/preloader";
import { showNotification } from "./utils/helpers";

// Preloader.setPreloader();

window.showNotification = showNotification;