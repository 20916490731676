import axios from 'axios'

export const resolve = async (promise) => {
    const resolved = {
        data: null,
        error: null
    };

    try {
        resolved.data = await promise;
    } catch (e) {
        resolved.error = e;
        resolved.error.data = e.response.data.data
        resolved.error.status = e.response.status
        // console.log("e.response.", JSON.stringify(e.response))
    }

    return resolved;
}

export async function addToNewsletter(payload) {
    return await resolve(
        axios.post('/api/newsletter/save', payload).then(res => res.data.data)
    )
}

export async function getCounters() {
    return await resolve(
        axios.post('/api/counters').then(res => res.data.data)
    )
}