import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css'
import { countersAnim } from '../animations/home/counters';
import { countersAnim2 } from '../animations/home/bag';

window.addEventListener('DOMContentLoaded', function () {
    swiperInit()
});
window.addEventListener('load', function () {
    window.dispatchEvent(new Event('resize'));

    animations();

    animations2();
})

function swiperInit() {
    const swiperMain = new Swiper(
        '.home-carousel',
        {
            mode: 'horizontal',
            loop: true,
            autoplay: {
                disableOnInteraction: false,
                delay: 5000,
            },
            slidesPerView: 1,
            pagination: {
                clickable: true,
                el: '#banners-pagination',
                type: 'bullets',
            },
        }
    )
    const swiperBlog = new Swiper(
        '.home-blog',
        {
            mode: 'horizontal',
            loop: false,
            autoplay: {
                disableOnInteraction: false,
                delay: 5000,
            },
            slidesPerView: 3,
            pagination: {
                clickable: true,
                el: '#blog-pagination',
                type: 'bullets',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                800: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
            }
        }
    )
    const mySwiper3 = new Swiper(
        ".myProduct", {
        slidesPerView: 1,
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            420: {
                slidesPerView: 1,
                spaceBetween: 20
            },
            800: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 60,
            },
        }
    }
    )

    const mySwiper = new Swiper(
        ".mySolution", {
        slidesPerView: 4,
        spaceBetween: 60,
    }
    )

    const mySwipe4 = new Swiper(
        ".solut", {
        slidesPerView: 2,
        spaceBetween: 5,
    }
    )

    

    const mySwiper2 = new Swiper(".myCategory", {
        slidesPerView: 1,
        grid: {
          rows: 1,
        },
        spaceBetween: 20,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            420: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            800: {
                slidesPerView: 2,
                spaceBetween: 30,
                grid: {
                    rows: 2,
                  },
            },
            900: {
                slidesPerView: 3,
                spaceBetween: 30,
                grid: {
                    rows: 2,
                  },
            },
        }
    }
    )

    const swiperValor = new Swiper(
        '.home-valor',
        {
            
            slidesPerView: 1,
            spaceBetween: 30,
            pagination: {
                clickable: true,
                el: '#banners-pagination',
                type: 'bullets',
            },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        420: {
            slidesPerView: 1,
            spaceBetween: 30
        },
        800: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        900: {
            slidesPerView: 4,
            spaceBetween: 70,
        },
    }
        }
    )

    const swiperCadena = new Swiper(
        '.home-cadena',
        {
            
            slidesPerView: 4,
            spaceBetween: 30,
            pagination: {
                clickable: true,
                el: '#banners-pagination',
                type: 'bullets',
            },
        }
    )


    const swiperExito = new Swiper(
        '.home-exito',
        {
            mode: 'horizontal',
            loop: false,
            autoplay: {
                disableOnInteraction: false,
                delay: 5000,
            },
            slidesPerView: 3,
            pagination: {
                clickable: true,
                el: '#exito-pagination',
                type: 'bullets',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                800: {
                    slidesPerView: 2,
                    spaceBetween: 30
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
            }
        }
    )
}

function animations () {
    countersAnim()
}

function animations2 () {
    countersAnim2()
}