var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "color-dark" },
    [
      _c(
        "el-form",
        { staticClass: "contact-form", attrs: { model: _vm.form } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  name: "name",
                  type: "text",
                  placeholder: "Nombre completo*",
                  requiered: ""
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "company" } },
            [
              _c("el-input", {
                attrs: {
                  name: "company",
                  type: "text",
                  placeholder: "Empresa",
                  requiered: ""
                },
                model: {
                  value: _vm.form.company,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "company", $$v)
                  },
                  expression: "form.company"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "email" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  name: "email",
                  placeholder: "Email*",
                  requiered: ""
                },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "phone" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  name: "phone",
                  placeholder: "Teléfono (10 digitos)*",
                  requiered: ""
                },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "message", label: "Mensaje" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", name: "message", rows: "5" },
                model: {
                  value: _vm.form.message,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "accept" } },
            [
              _c("el-checkbox", { staticClass: "mt-2" }, [
                _c("span", [
                  _vm._v(
                    "He leído y Acepto que Etiquetas Electrónicas de Occidente protegerá mis datos como se detalla en el "
                  )
                ]),
                _vm._v(" "),
                _c("a", { attrs: { href: "" } }, [
                  _vm._v("Aviso de Privacidad")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "primary-button mx-auto mt-4",
              attrs: { type: "button" },
              on: { click: _vm.submitForm }
            },
            [
              _c("span", { staticClass: "text-size_18 p-1" }, [
                _vm._v("Enviar")
              ]),
              _vm._v(" "),
              _c("div", {}, [
                _c("img", {
                  staticStyle: { "margin-left": "-20px" },
                  attrs: {
                    src: "../build/images/izq.svg",
                    width: "25",
                    height: "25",
                    alt: "Arrow Right Icon"
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4 text-size_14" }, [
            _c("span", [_vm._v("*Campos obligatorios")])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }