var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c(
        "el-form",
        {
          staticClass: "contact-form",
          attrs: {
            model: _vm.form,
            method: "post",
            enctype: "multipart/form-data"
          }
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "row justify-content-center",
              attrs: { prop: "area" }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "border",
                  staticStyle: { width: "300px" },
                  attrs: { placeholder: "Selecciona el Área de interés" },
                  model: {
                    value: _vm.form.area,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "area", $$v)
                    },
                    expression: "form.area"
                  }
                },
                [
                  _c("el-option", {
                    attrs: { label: "Ventas", value: "Ventas" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Manufactura", value: "Manufactura" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Sistemas", value: "Sistemas" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Contabilidad", value: "Contabilidad" }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: {
                      label: "Recursos humanos",
                      value: "Recursos humanos"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-option", {
                    attrs: { label: "Compras", value: "Compras" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "name" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  clas: "",
                  placeholder: "Nombre completo*"
                },
                model: {
                  value: _vm.form.name,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "email" } },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "Email*" },
                model: {
                  value: _vm.form.email,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "phone" } },
            [
              _c("el-input", {
                attrs: { type: "text", placeholder: "Teléfono (10 digitos)*" },
                model: {
                  value: _vm.form.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "message", label: "Mensaje" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: "5" },
                model: {
                  value: _vm.form.message,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "message", $$v)
                  },
                  expression: "form.message"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { staticClass: "mt-3", attrs: { prop: "file" } }, [
            _c("div", { staticClass: "d-flex row justify-content-center" }, [
              _c(
                "div",
                { staticClass: "col-12 row justify-content-center" },
                [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "el-upload__tip mr-2 h5",
                        attrs: { slot: "tip" },
                        slot: "tip"
                      },
                      [_vm._v("Subir CV")]
                    ),
                    _vm._v(" (PDF no mayor a 5MB)\n                    ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo",
                      attrs: {
                        "on-change": _vm.handleFileChange,
                        "before-upload": _vm.beforeFileUpload,
                        "auto-upload": false,
                        limit: 1
                      },
                      model: {
                        value: _vm.form.file,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "file", $$v)
                        },
                        expression: "form.file"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "primary-button ml-2",
                          attrs: {
                            slot: "trigger",
                            size: "small",
                            type: "danger"
                          },
                          slot: "trigger"
                        },
                        [
                          _c(
                            "k-icon",
                            { staticClass: "h3", attrs: { icon: "arrowUp" } },
                            [_vm._v("↑")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { prop: "accept" } },
            [
              _c("el-checkbox", { staticClass: "mt-2" }, [
                _c("span", [
                  _vm._v(
                    "He leído y Acepto que Etiquetas Electrónicas de Occidente protegerá mis datos como se detalla en el "
                  )
                ]),
                _vm._v(" "),
                _c("a", { attrs: { href: "" } }, [
                  _vm._v("Aviso de Privacidad")
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "primary-button mx-auto mt-4",
              attrs: { type: "button" },
              on: { click: _vm.submitForm }
            },
            [
              _c("span", { staticClass: "text-size_18 p-1" }, [
                _vm._v("Enviar")
              ]),
              _vm._v(" "),
              _c("div", {}, [
                _c("img", {
                  staticStyle: { "margin-left": "-20px" },
                  attrs: {
                    src: "../build/images/izq.svg",
                    width: "25",
                    height: "25",
                    alt: "Arrow Right Icon"
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4 text-size_14" }, [
            _c("span", [_vm._v("*Campos obligatorios")])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }