import Vue from 'vue'
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/es'
import 'element-ui/lib/theme-chalk/index.css' // <- Default ElementUI styles package
// import "../../styles/element/theme/index.css" <- For custom ElementUI styles package

import KIcon from '../components/icons/KIcon'
import Newsletter from '../components/Newsletter.vue'
import ContactForm from '../components/ContactForm.vue'
import BagForm from '../components/BagForm.vue'

Vue.use(ElementUI, {locale});

new Vue ({
    el: '#app',
    components: {
        KIcon,
        ContactForm,
        BagForm,
        Newsletter,
    },
});