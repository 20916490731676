<template>
    <div class="" >
        <el-form  class="contact-form" :model="form"  method="post" enctype="multipart/form-data">
            <el-form-item prop="area" class="row justify-content-center">
                <el-select v-model="form.area" class="border" style="width:300px;" placeholder="Selecciona el Área de interés" >
                  <el-option label="Ventas" value="Ventas"></el-option>
                  <el-option label="Manufactura" value="Manufactura"></el-option>
                  <el-option label="Sistemas" value="Sistemas"></el-option>
                  <el-option label="Contabilidad" value="Contabilidad"></el-option>
                  <el-option label="Recursos humanos" value="Recursos humanos"></el-option>
                  <el-option label="Compras" value="Compras"></el-option>
                </el-select>
              </el-form-item>

            <el-form-item prop="name">
                <el-input v-model="form.name" type="text" clas placeholder="Nombre completo*"/>
            </el-form-item>
            <el-form-item prop="email">
                <el-input v-model="form.email" type="text" placeholder="Email*"/>
            </el-form-item>
            <el-form-item prop="phone">
                <el-input v-model="form.phone" type="text" placeholder="Teléfono (10 digitos)*"/>
            </el-form-item>
            <el-form-item prop="message" label="Mensaje">
                <el-input v-model="form.message" type="textarea" rows="5"/>
            </el-form-item>

            <el-form-item prop="file" class="mt-3">
                <div class="d-flex row justify-content-center">
                    <div class="col-12 row justify-content-center">
                        <div>
                            <span slot="tip" class="el-upload__tip mr-2 h5">Subir CV</span> (PDF no mayor a 5MB)
                        </div>
                        <el-upload
          class="upload-demo"
          v-model="form.file"
          :on-change="handleFileChange"
          :before-upload="beforeFileUpload"
          :auto-upload="false"
          :limit="1"
        >
                            <el-button
                                slot="trigger"
                                size="small"
                                type="danger"
                                class="primary-button ml-2"
                            >
                                <k-icon icon="arrowUp" class="h3">↑</k-icon>
                            </el-button>
                        </el-upload>
                    </div>
                </div>
            </el-form-item>
             

            <el-form-item prop="accept">
                <el-checkbox class="mt-2">
                    <span>He leído y Acepto que Etiquetas Electrónicas de Occidente protegerá mis datos como se detalla en el </span>
                    <a href="">Aviso de Privacidad</a>
                </el-checkbox>
            </el-form-item>

            

            <button type="button" class="primary-button mx-auto mt-4" @click="submitForm">
                <span class="text-size_18 p-1">Enviar</span>
                <div class="">
                    <img :src="`../build/images/izq.svg`" width="25" height="25" alt="Arrow Right Icon" style="margin-left:-20px;" />
                </div>
            </button>

            <div class="mt-4 text-size_14">
                <span>*Campos obligatorios</span>
            </div>
        </el-form>
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import axios from 'axios';

export default {

    data () {
        return {
            form: {
                name: '',
        area:'',
        email: '',
        phone:'',
        message:'',
        file: null,
            },

        }
    },
        methods: {
        async submitForm(event) {
        event.preventDefault(); // Evitar recarga de página

        try {
            const formData = new FormData();
                formData.append('name', this.form.name);
                formData.append('area', this.form.area);
                formData.append('email', this.form.email);
                formData.append('phone', this.form.phone);
                formData.append('message', this.form.message);
                formData.append('file', this.form.file);

                const response = await axios.post('/send-email-bag', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
            // Muestra una alerta de éxito personalizada
            Swal.fire({
                icon: 'success',
                title: '¡Correo enviado con éxito!',
                text: 'Gracias por ponerte en contacto con nosotros.',
            });

            // Realiza cualquier acción necesaria después de enviar el formulario
        } catch (error) {
            console.error('Error al enviar el formulario', error);

            // Muestra una alerta de error personalizada
            Swal.fire({
                icon: 'error',
                title: '¡Error al enviar el formulario!',
                text: 'Hubo un problema al enviar el correo. Por favor, inténtalo nuevamente.',
            });
        }
    },
    handleFileChange(file) {
      this.form.file = file.raw; // Almacenar el archivo en la propiedad form.file
    },
    beforeFileUpload(file) {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        this.$message.error('El archivo debe ser un PDF');
      }
      return isPDF;
    },
    }
}
</script>
<style scoped>

</style>